.banner__carousel{
	display: block;

	.carousel{
		position: relative;
		background: #006d5a;

		.carousel-indicators{
			margin-bottom: 40px;
			z-index: 998;

			@include media-breakpoint-down(sm){
				margin-bottom: 12px;
			}

			li{
				width: 14px;
				height: 14px;
				border-radius: 50%;
				background: #fff;
				opacity: 1;
				transition: background .4s linear;
				border-top: 0;
				border-bottom: 0;
				margin-right: 15px;
			}

			.active{
				background: map-get($cores, cor-secundaria);
			}
		}

		.carousel-inner{
			width: unset;
			overflow: unset;
			position: relative;
			display: block;
		}

	}

	.banner__personalizado{
		position: relative;
		display: block;

		.img__background{
			position: relative;
		}

		.texto__principal{
			z-index: 3;
			position: absolute;
			left: 0;
			top: 0;
			animation: zoomIn 2s linear;
		}

		.palavras{
			opacity: .3;
			z-index: 2;
			position: absolute;
			left: 15px;
			top: 0;
			animation: fadeInLeft 3s linear;
		}
	}
}


.secao__sobre__nos{
	padding-top: 100px;

	@include media-breakpoint-down(md){
		padding-top: 50px;
	}

	.title{
		font-size: 15px;
		font-weight: 400;
		font-family: 'Montserrat', sans-serif;
		color: map-get($cores, cor-primaria);
		margin-bottom: 18px;

		@include media-breakpoint-down(md){
			text-align: center;
		}
	}

	.topo__secao{
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 80px;

		@include media-breakpoint-down(md){
			flex-direction: column;
			margin-bottom: 30px;
		}

		.subtitle{
			width: 265px;
			font-size: 30px;
			line-height: 32px;
			font-weight: 700;
			font-family: 'Montserrat', sans-serif;
			color:  map-get($cores, cor-terciaria);

			span{
				color: map-get($cores, cor-primaria);
			}

			@include media-breakpoint-down(md){
				width: unset;
				text-align: center;
				margin-bottom: 20px;
			}
		}
	}



	.descricao{
		font-size: 20px;
		line-height: 24px;
		font-weight: 400;
		font-family: 'Montserrat', sans-serif;
		color: map-get($cores, cor-terciaria);
		text-align: justify;
		width: 100%;
		max-width: 730px;

		@include media-breakpoint-down(lg){
			max-width: 500px;
		}

		@include media-breakpoint-down(md){
			max-width: 730px;
		}
	}

	.content{
		display: flex;
		align-items: center;

		@include media-breakpoint-down(md){
			flex-direction: column-reverse;
			align-items: center;
		}

		.box__part1, .box__part2{
			width: 50%;

			@include media-breakpoint-down(md){
				width: 100%;
			}
		}

		.box__part1{
			transform: translateX(-200px);
			z-index: -2;

			@include media-breakpoint-down(lg){
				transform: translateX(-520px) !important;
			}

			@media(max-width: 1500px){
				transform: translateX(-380px);
			}

			img{
				border-top-right-radius: 40px;
			}

			@include media-breakpoint-down(md){
				display: flex;
				justify-content: center;
				transform: unset;
				margin-top: 30px;
				transform: unset !important;

				img{
					width: 100%;
					height: auto;
					display: block;
				}
			}

		}

		.box__part2{
			position: relative;

			&::before{
				content: '';
				width: 100vw;
				height: 200px;
				border-top-left-radius: 40px;
				background: #05bbaa;
				position: absolute;
				bottom: -40px;
				z-index: -1;

				@include media-breakpoint-up(lg) {
					right: -70.333%;
				}

				@include media-breakpoint-down(md){
					display: none;
				}
			}
		}

		.box__descricao {
			width: 100%;
			max-width: 542px;
			margin-right: auto;
			z-index: 2;
			position: relative;

			.subtitle {
				width: 100%;
				max-width:365px;
				font-size: 30px;
				line-height: 32px;
				font-weight: 700;
				font-family: 'Montserrat', sans-serif;
				color:  map-get($cores, cor-terciaria);
				margin-bottom: 145px;
				margin-right: auto;
				margin-left: auto;

				span{
					color: map-get($cores, cor-primaria);
				}

				@include media-breakpoint-down(md){
					width: unset;
					max-width: unset;
					text-align: center;
					margin-bottom: 30px;
				}
			}

			.descricao{
				z-index: 2;
				text-align: justify;

				@include media-breakpoint-up(lg){
					color: #fff;

					&::-webkit-scrollbar {
						width: 15px;               /* width of the entire scrollbar */
					  }

					&::-webkit-scrollbar-track {
					background: #fff;        /* color of the tracking area */
					}

					&::-webkit-scrollbar-thumb {
					background-color: map-get($cores, cor-primaria);    /* color of the scroll thumb */
					border-radius: 0px;       /* roundness of the scroll thumb */
					border: 5px solid #fff;  /* creates padding around scroll thumb */
					}
				}
			}

			@include media-breakpoint-down(md){
				margin-left: auto;
				padding-left: 15px;
				padding-right: 15px;
			}
		}
	}
}

.secao__servicos{
	padding-top: 100px;
	padding-bottom: 100px;

	@include media-breakpoint-down(md){
		padding-top: 50px;
		padding-bottom: 50px;
	}

	.title{
		font-size: 15px;
		font-weight: 400;
		font-family: 'Montserrat', sans-serif;
		color: map-get($cores, cor-primaria);
		margin-bottom: 20px;
		text-align: center;

		@include media-breakpoint-down(md){
			text-align: center;
		}
	}

	.subtitle{
		font-size: 30px;
		line-height: 32px;
		font-weight: 700;
		font-family: 'Montserrat', sans-serif;
		color:  map-get($cores, cor-terciaria);
		margin-bottom: 45px;
		text-align: center;
	}

	.owl-carousel{
		position: relative;

		.owl-stage-outer{
			padding-top: 20px;
			padding-bottom: 20px;
		}

		.owl-item{
			display: flex;
			justify-content: center;
		}

		.owl-nav{
			.owl-prev{
				width: 30px;
				height: 30px;
				border-radius: 0;
				border: 0;
				background: transparent;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 25px;
				color: #000;
				transition: color .4s linear;
				position: absolute;
				left: -30px;
				top: calc(50% - 15px);

				&:hover{
					color: map-get($cores, cor-primaria);
				}

				@include media-breakpoint-down(sm){
					left: -15px;
				}
			}

			.owl-next{
				width: 30px;
				height: 30px;
				border-radius: 0;
				border: 0;
				background: transparent;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 25px;
				color: #000;
				transition: color .4s linear;
				position: absolute;
				right: -20px;
				top: calc(50% - 15px);

				&:hover{
					color: map-get($cores, cor-primaria);
				}

				@include media-breakpoint-down(sm){
					right: -15px;
				}
			}
		}
	}

	.card__servico{
		width: 100%;
		position: relative;

		@include media-breakpoint-down(md){
			border-radius: 50px;
			width: 540px;
			box-shadow: 0px 0px 14px 0px rgba(0,0,0,0.75);
			-webkit-box-shadow: 0px 0px 14px 0px rgba(0,0,0,0.75);
			-moz-box-shadow: 0px 0px 14px 0px rgba(0,0,0,0.75);
		}

		@include media-breakpoint-down(sm){
			width: 270px;
		}

		a{
			text-decoration: none;
			display: block;
			display: flex;

			@include media-breakpoint-down(md){
				flex-direction: column;
				align-items: center;
			}
		}

		&:hover{
			.content__card{
				.btn__mais{
					border-bottom: 2px solid map-get($cores, cor-primaria);
				}
			}
		}

		.box__img__card{
			width: 540px;
			height: 400px;
			position: relative;
			border-radius: 50px;
			z-index: 1;

			@include media-breakpoint-down(sm){
				width: 270px;
				height: 200px;
			}

			img{
				width: 100%;
				height: 100%;
				position: absolute;
				left: 0;
				bottom: 0;
				border-radius: 50px;
			}
		}

		.content__card{
			width: 636px;
			height: 280px;
			border-top-left-radius: 50px;
			border-top-right-radius: 50px;
			border-bottom-right-radius: 50px;
			padding: 35px 30px;
			position: absolute;
			right: 15px;
			z-index: 2;
			background: #fff;
			top: calc(50% - 140px);
			box-shadow: 0px 0px 13px 0px rgba(0,0,0,0.75);
			-webkit-box-shadow: 0px 0px 13px 0px rgba(0,0,0,0.75);
			-moz-box-shadow: 0px 0px 13px 0px rgba(0,0,0,0.75);


			@include media-breakpoint-down(md){
				padding: 20px;
				position: relative;
				top: unset;
				right: unset;
				border-radius:0;
				box-shadow: none;
				width: unset;
				height: unset;
				border-bottom-left-radius: 50px;
				border-bottom-right-radius: 50px;

			}

			.titulo{
				font-size: 30px;
				line-height: 32px;
				font-weight: 700;
				font-family: 'Montserrat', sans-serif;
				color:  map-get($cores, cor-terciaria);
				margin-bottom: 15px;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 3;
				overflow: hidden;

				@include media-breakpoint-down(sm){
					font-size: 17px;
					line-height: 20px;
				}
			}

			.descritivo{
				font-size: 15px;
				line-height: 18px;
				height: 100px;
				font-weight: 400;
				font-family: 'Montserrat', sans-serif;
				color: map-get($cores, cor-terciaria);
				text-align: justify;
				margin-bottom: 12px;

				@include media-breakpoint-down(md){
					overflow-y: scroll;
				}
			}

			.btn__mais{
				color: map-get($cores, cor-primaria);
				font-size: 15px;
				font-weight: 500;
				font-family: 'Montserrat', sans-serif;
				transition: all .4s linear;
				border-bottom: 2px solid transparent;
				border-radius: 0;
				padding:  0;
			}
		}
	}

	#formulario{
		.modal-header{
			border-bottom: 0;
		}
	}

	#servico{

		.modal-header{
			border-bottom: 0;
		}

		.close{
			color: #fff;
		}

		.modal-content{
			border-radius: 50px;
			background: map-get($cores, cor-primaria);
			color: #fff;

			@include media-breakpoint-down(md){
				border-radius: 20px;
			}
		}

		.titulo__modal{
			font-size: 30px;
			line-height: 32px;
			font-weight: 700;
			font-family: 'Montserrat', sans-serif;
			margin-bottom: 25px;
			text-align: center;
		}

		.descricao__modal{
			font-size: 18px;
			line-height: 24px;
			font-weight: 400;
			font-family: 'Montserrat', sans-serif;
			text-align: justify;
			width: 100%;
			max-width: 700px;
			margin-left: auto;
			margin-right: auto;
		}
	}
}

.secao__simulacao{
	padding-top: 80px;
	padding-bottom: 80px;
	background-color: map-get($cores, cor-primaria);
	--bg: url("../images/bg-simulacao.png");
	background-image: var(--bg, url("../images/bg-simulacao.png"));
	background-position: left top;
	background-repeat: no-repeat;
	color: #fff;

	@include media-breakpoint-down(md){
		padding-top: 50px;
		padding-bottom: 50px;
		margin-left: auto;
		margin-right: auto;
		--bg: url("../images/bg-simulacao.png");
		background-image: var(--bg, url("../images/bg-simulacao.png"));
		background-position: right top;
		background-repeat: no-repeat;
	}

	.box__descricao{
		width: 100%;
		max-width: 545px;
	}

	.title{
		font-size: 30px;
		font-weight: 700;
		font-family: 'Montserrat', sans-serif;
		line-height: 32px;
		margin-bottom: 25px;
		@include media-breakpoint-down(sm){
			font-weight: 900;
		}
	}

	.descricao{
		color: #fff;
		font-weight: 400;
		font-size: 16px;
		text-align: justify;
		line-height: 18px;
		font-family: 'Montserrat', sans-serif;

		@include media-breakpoint-down(sm){
			font-weight: 700;
		}
	}

	.btn__mais{
		padding: 0;
		width: 215px;
		border: 0;
		background: transparent;
		color: #fff;
		font-size: 15px;
		font-weight: 500;
		font-family: 'Montserrat', sans-serif;
		margin-top: 30px;
		border-bottom: 2px solid transparent;
		transition: all .4s linear;
		border-radius: 0;
		box-shadow: none;

		&:hover{
			border-bottom: 2px solid #fff;
		}
	}

}
