/* Ocultando quando desktop */
@include media-breakpoint-up(lg){
    .mobile__controls{
        display: none;
    }
}

/* Exibe quando mobile/tablet */
@include media-breakpoint-down(md){
    .mobile__controls{
        display: flex;
    }
}

/* Definindo os estilos padrões do .mobile__controls */
.mobile__controls{
    justify-content: space-between;
	box-shadow: 0 10px 10px -5px rgb(236, 236, 236);
	.logo{
		padding: 10px;
		align-self: center;
		object-fit: contain;
		max-width: 150px;
		width: auto;
		height: auto;
		max-height: 70px;
        margin-left: auto;
	}

	.btn{
		border-radius: 0;
		color:  map-get($cores, cor-primaria);
		font-weight: bold;
		box-shadow: none;
		margin-left: auto;
	}
}
