/*DESKTOP*/
@include media-breakpoint-up(lg){
    .main__topo{
        width: 100%;
        max-width: 1145px;
        padding-left: 15px;
        padding-right: 15px;
        margin-left: auto;
		align-items: center;
        margin-right: auto;
        display: flex;
		justify-content: space-between;
		.logo{
			padding: 10px 0;
		}

		.menu{
			display: flex;
			list-style: none;
			padding-left: 0;
			margin-left: auto;
			margin-bottom: 0;
			li{
				display: flex;
				align-items: center;
				padding-left: 20px;
				padding-right: 20px;
			}

			a{
				align-items: center;
				color: map-get($cores, cor-primaria);
				font-size: 14px;
				font-family: 'Montserrat', sans-serif;
				font-weight: 400;
				transition: all .3s linear;
				text-decoration: none;
				position: relative;

				&:after{
					content:'';
					width: 1px;
					height: 30px;
					background: map-get($cores, cor-primaria);
					position: absolute;
					left: calc(50% - 1px);
					top: -38px;
					opacity: 0;
					transition: opacity .4s linear;
				}

				&:before{
					content:'';
					width: 1px;
					height: 30px;
					background: map-get($cores, cor-primaria);
					position: absolute;
					left: calc(50% - 1px);
					bottom: -38px;
					opacity: 0;
					transition: opacity .4s linear;
				}

				&:hover{
					&::after{
						opacity: 1;
					}

					&::before{
						opacity: 1;
					}
				}

				&:focus{
					&::after{
						opacity: 1;
					}

					&::before{
						opacity: 1;
					}
				}
			}

		}

		.redes__icones{
			display: none;
		}
    }
}


/*MOBILE*/
@include media-breakpoint-down(md){
    .main__topo{
        position: fixed;
        left: 0;
        top: 0;
        width: 250px;
        height: 100%;
		background: #fff;
        z-index: 999;
        overflow: auto;
        transform: translateX(-100%);

		.logo{
			padding: 15px;
			text-align: center;
		}

		.menu{
			list-style: none;
			padding-left: 0;
			margin-bottom: 0;
			margin-top: 30px;


			a{
				display: block;
				padding: 10px 20px;
				font-size: 15px;
				color: map-get($cores, cor-primaria);
				font-family: 'Montserrat',sans-serif;
				font-weight: 400;
				transition: all .3s linear;
				position: relative;
				background: transparent;
				border-bottom: 3px solid transparent;
				text-decoration: none;

				&:hover{
					border-bottom: 3px solid map-get($cores, cor-secundaria);
					color: map-get($cores, cor-secundaria);
					font-weight: 700;
				}

				&:focus{
					border-bottom: 3px solid map-get($cores, cor-secundaria);
					color: map-get($cores, cor-secundaria);
					font-weight: 700;
				}
			}
		}

		.redes__icones	{
			display: flex;
			justify-content: center;
			align-items: center;
			margin-top: 30px;

			a{
				width: 36px;
				height: 36px;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
				color: #fff;
				background: map-get($cores, cor-secundaria);
				font-size: 22px;
				margin-right: 10px;
				text-decoration: none;

				&:hover{
					animation: rubberBand 1s linear;
				}
			}
		}

    }


    .main__topo.main__topo--shown{
        animation: showMenu .4s linear forwards;
    }

    .main__topo.main__topo--hide{
        animation: hideMenu .4s linear forwards;
    }

    .backdrop{
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,.8);
        backdrop-filter: blur(4px);
        display: none;
    }

    .backdrop.main__topo--shown{
        display: block;
        animation: showBackdrop .4s linear forwards;
    }

    .backdrop.main__topo--hide{
        display: block;
        animation: hideBackdrop .4s linear forwards;
    }
}

@keyframes showBackdrop {
    from {
        opacity: 0;
    }

    to{
        opacity: 1;
    }
}

@keyframes hideBackdrop {
    to {
        opacity: 0;
    }

    from{
        opacity: 1;
    }
}

@keyframes showMenu {
    from {
        transform: translateX(-100%);
        opacity: 0;
    }

    to{
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes hideMenu {
    to {
        transform: translateX(-100%);
        opacity: 0;
    }

    from{
        transform: translateX(0);
        opacity: 1;
    }
}
