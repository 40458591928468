.rodape{
	--bg: url("../images/bg-rodape.png");
	background-image: var(--bg, url("../images/bg-rodape.png"));
	background-position: left top;
	background-repeat: no-repeat;
	background-color:  #ededed;

	.principal__rodape{
		width: 100%;
		max-width: 992px;
		padding-top: 75px;
		padding-bottom: 50px;
		display: flex;
		justify-content: space-between;
		margin-left: auto;
		margin-right: auto;
		border-bottom: 1px solid #c7c7c7;

		@include media-breakpoint-down(md){
			flex-direction: column;
			align-items: center;
		}

		.box__contato{
			width: 100%;
			max-width: 370px;

			@include media-breakpoint-down(md){
				margin-bottom: 40px;
			}

			.title{
				color: map-get($cores, cor-terciaria);
				font-weight: 700;
				font-size: 30px;
				font-family: 'Montserrat', sans-serif;
				margin-bottom: 12px;
			}

			.subtitle {
				color: map-get($cores, cor-primaria);
				font-weight: 500;
				font-size: 17px;
				font-family: 'Montserrat', sans-serif;
				margin-bottom: 20px;
			}

			.contato{
				color: map-get($cores, cor-terciaria);
				font-size: 16px;
				font-weight: 400;
				font-family: 'Montserrat', sans-serif;
				margin-bottom: 22px;

				a{
					color: map-get($cores, cor-terciaria)
				}

				p{
					margin-bottom: 0;
				}

				.ml{
					margin-left: 5px;
				}
			}

			.horario{
				max-width:  195px;

				@include media-breakpoint-down(md){
					max-width: unset;
				}
			}

			.redes__icones	{
				display: flex;
				align-items: center;

				@include media-breakpoint-down(md){
					justify-content: center;
				}

				a{
					width: 36px;
					height: 36px;
					border-radius: 50%;
					display: flex;
					justify-content: center;
					align-items: center;
					color: #fff;
					background: map-get($cores, cor-terciaria);
					font-size: 22px;
					margin-right: 10px;
					text-decoration: none;
					transition: background .4s linear;

					&:hover{
						background: map-get($cores, cor-secundaria);
						animation: rubberBand 1s linear;
					}
				}
			}
		}


		.box__form{
			width: 100%;
			max-width: 600px;
		}
	}


	.box__regulamento{
		padding-top: 50px;
		padding-bottom: 50px;
		width: 100%;
		max-width: 982px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-left: auto;
		margin-right: auto;

		.icone__regulamento{
			@include media-breakpoint-down(md){
				margin-bottom: 20px;
				display: flex;
				justify-content: center;
			}
		}

		@include media-breakpoint-down(md){
			flex-direction: column;
			align-items: center;
		}

		.descricao{
			width: 100%;
			max-width: 908px;
			font-weight: 400;
			font-family: 'Montserrat', sans-serif;
			font-size: 10px;
			line-height: 12px;
			color: map-get($cores, cor-terciaria);
			text-align: justify;

			p{
				margin-bottom: 0;
			}
		}
	}

	.sub__rodape{
		background: map-get($cores, cor-terciaria);
		width: 100%;
		padding-top: 30px;
		padding-bottom: 25px;
		color: #fff;
		font-size: 11px;
		line-height: 13px;
		font-weight: 700;
		font-family: 'Montserrat', sans-serif;
		text-align: center;

		p{
			margin-bottom: 0;
		}

		.gv8{
			display: flex;
			align-items: center;
			justify-content: center;

			svg{
				margin-left: 5px;
			}
		}
	}
}

.form__contato{
	width: 100%;

	.form-group{
		width: 100%;
		margin-bottom: 10px;
	}

	.group{
		display: grid;
		grid-template-columns: repeat(2,1fr);
		column-gap: 10px;

		@include media-breakpoint-down(sm){
			grid-template-columns: 1fr;
		}
	}

	.form-control{
		width: 100%;
		height: 40px;
		border-radius: 40px;
		padding-left: 20px;
		border: 0;
		box-shadow: none;
		background: #dbdbdb;
		font-size: 12px;
		font-weight: 400;
		font-family: 'Montserrat', sans-serif;
		color: map-get($cores, cor-terciaria);

		&::placeholder{
			font-size: 12px;
			font-weight: 400;
			font-family: 'Montserrat', sans-serif;
			color: map-get($cores, cor-terciaria);
		}
	}

	textarea{
		border-radius: 15px !important;
		padding-top: 15px;
		height: 145px !important;
	}

	.checkbox{
		color: #666666;
		font-size: 12px;
		font-family: 'Montserrat', sans-serif;
		padding-left: 40px;
		font-weight: 400;
		a{
			color: #666666;
			margin-left: 5px;
		}

		label{
			padding-top: 2px;
		}

		[type="checkbox"]:checked,
		[type="checkbox"]:not(:checked) {
			position: absolute;
			left: -9999px;
		}
		[type="checkbox"]:checked + label,
		[type="checkbox"]:not(:checked) + label
		{
			position: relative;
			padding-left: 28px;
			cursor: pointer;
			line-height: 20px;
			display: inline-block;
			color: #666;
		}
		[type="checkbox"]:checked + label:before,
		[type="checkbox"]:not(:checked) + label:before {
			content: '';
			position: absolute;
			left: 0;
			top: 5px;
			width: 12px;
			height: 12px;
			border: 1px solid #666666;
			border-radius: 100%;
			background: #fff;
		}
		[type="checkbox"]:checked + label:after,
		[type="checkbox"]:not(:checked) + label:after {
			content: '';
			width: 4px;
			height: 4px;
			background: map-get($cores, cor-terciaria);
			position: absolute;
			top: 9px;
			left: 4px;
			border-radius: 100%;
			-webkit-transition: all 0.2s ease;
			transition: all 0.2s ease;
		}
		[type="checkbox"]:not(:checked) + label:after {
			opacity: 0;
			-webkit-transform: scale(0);
			transform: scale(0);
		}
		[type="checkbox"]:checked + label:after {
			opacity: 1;
			-webkit-transform: scale(1);
			transform: scale(1);
		}
	}

	.btn__enviar{
		width: 170px;
		height: 40px;
		border-radius: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		background: map-get($cores, cor-secundaria);
		color: #fff;
		font-family: 'Montserrat', sans-serif;
		font-weight: 700;
		font-size: 14px;
		transition: background .4s linear;
		margin-left: auto;
		margin-right: auto;
		margin-top: 15px;

		&:hover{
			background: map-get($cores, cor-primaria);
		}
	}
}
