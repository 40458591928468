@import "_mobile_controls";
@import "_main_topo";

.topo{
	width: 100%;
    position: relative;
	background: #fff;
    left: 0;
    top: 0;
    z-index: 1000;
	transition: all .4s linear;
}


.menu-fixo {
    position: fixed;
    top: 0;
    z-index: 999;
    transition: all .5s;
	box-shadow: 0px 2px 12px 0px rgba(0,0,0,0.75);
	-webkit-box-shadow: 0px 2px 12px 0px rgba(0,0,0,0.75);
	-moz-box-shadow: 0px 2px 12px 0px rgba(0,0,0,0.75);
}

/**************************************************************************
						MOBILE/TABLET
***************************************************************************/
@include media-breakpoint-down(md){
	.topo{
		position: sticky;
	}
}
