.politica__de__privacidade, .termos__de__uso{
	padding-top: 100px;
	padding-bottom: 100px;

	@include media-breakpoint-down(md){
		padding-top: 50px;
	}

	.title__page{
		font-size: 40px;
		font-weight: bold;
		font-family: 'Montserrat',sans-serif;
		position: relative;
		margin-bottom: 40px;
	}


	.descricao{
		font-size: 13px;
		line-height: 16px;
		text-align: justify;
		font-family: 'Montserrat',sans-serif;
		font-weight: 500;
	}

	.btn__voltar{
		width: 200px;
		height: 50px;
		border-radius: 50px;
		background: map-get($cores, cor-primaria);
		color: #fff;
		font-family: 'Montserrat',sans-serif;
		font-weight: 700;
		font-size: 14px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-left: auto;
		margin-right: auto;
		margin-top: 30px;
		box-shadow: none;

	}
}
